import React from "react";
import {InfoLayout} from "../../Layouts/InfoLayout";

export const TermsOfUse: React.FunctionComponent = (_props) => {
    const HTML = (
        <div>
            <p>La página web de Diazar, incluyendo todas sus características, contenido y servicios son servicios puestos a disposición del público por parte de Diazar. Usted puede acceder y utilizar esta página web sólo bajo los siguientes términos y condiciones.</p>
            <p>Estas Condiciones del servicio se aplican a todos los usuarios de esta Página Web. Al utilizar esta Página Web, usted acepta estos Términos de Servicio y la Política de Privacidad de Diazar, que se incorpora por referencia en estas Condiciones de Servicio y forman parte del mismo. Si usted no está de acuerdo con las Condiciones de Servicio, usted debe suspender el uso de este sitio web.</p>
            <p><strong>SITIO WEB DE LICENCIA LIMITADA</strong></p>
            <p>Como usuario de este sitio Web se le concede una licencia, intransferible, revocable y no exclusiva y limitada para acceder y utilizar este Sitio Web de acuerdo con las Condiciones de Servicio. Diazar puede terminar esta licencia en cualquier momento y por cualquier razón.</p>
            <p><strong>LIMITACIONES EN Y ÁMBITO DE USO</strong></p>
            <p>Está prohibido cualquier uso no autorizado de este sitio web. Usted no puede utilizar este sitio Web para:</p>
            <p>Copiar, modificar, reproducir, publicar, distribuir, exhibir o transmitir con fines públicos la totalidad o parte de esta página web comercial, sin fines de lucro o no. Determinar la arquitectura de la página o extraer datos o información sobre el uso, las identidades individuales de otros usuarios de este Página Web a través del uso de cualquier supervisión de red o software de descubrimiento o de cualquier otra manera.</p>
            <p>Monitorear, copiar, escanear, revisar, exhibir, ping o validar este sitio Web a través de robots, arañas, otros software o dispositivo automático, proceso, método o metodología, manual o de otro tipo (métodos como raspado web, recolección, extracción de datos, los validación de datos o verificación están prohibidos)</p>
            <p>Transmitir cualquier virus informático, gusano, defecto, caballo de Troya o cualquier otro elemento de naturaleza destructiva, o para cargar cualquier virus o código malicioso. Transmitir información falsa, engañosa, comunicaciones fraudulentas o ilegales, información o datos. Phish, parodia, cometer actividades ilegales o fraudulentas o violar leyes en su jurisdicción.</p>
            <p>Acceder a información no autorizada.</p>
            <p>Transmitir o publicar cualquier material que sea abusivo, acosador, agraviante, difamatorio, vulgar, pornográfico, obsceno, difamatorio, fraudulento, invasivo de la privacidad, odioso, o racista, xenófobo o odioso de cualquier otra forma, o participar con una conducta amenazadora.</p>
            <p>Transmitir cualquier anuncio no solicitado o no autorizado, materiales promocionales, correo basura, spam, cartas en cadena, concursos, esquemas piramidales, encuestas, o cualquier otra forma de solicitación o mensajería masiva, ya sea de naturaleza comercial o no.</p>
            <p><strong>DERECHOS DE PROPIEDAD INTELECTUAL</strong></p>
            <p>Salvo lo dispuesto expresamente en estas Condiciones de Servicio, nada de lo aquí contenido se interpretará como otorgamiento de licencia o derecho, por implicación, impedimento o de otro modo, en virtud de los derechos de autor u otros derechos de propiedad intelectual. Usted acepta que este sitio web está protegido por derechos de autor, marcas comerciales, marcas de servicio, patentes y otros derechos y leyes de propiedad. Diazar, el logotipo de Diazar y todas las demás marcas comerciales Diazar, marcas de servicio, nombres de productos y nombres comerciales de Diazar que aparezca en o en conexión con este sitio web son propiedad de Diazar. Diazar no le otorga el derecho de usar o mostrar cualquier marca comercial, marca de servicio, nombre del producto, nombre comercial o logotipo que aparezca en este sitio web sin el consentimiento previo por escrito de Diazar.</p>
            <p><strong>LICENCIA DE CONTENIDO</strong></p>
            <p>Al postear, publicar, subir, o distribuir cualquier dato, información, texto, gráficos, vínculos, mensajes, comentarios, contenido u otros materiales para su uso en este sitio web, usted otorga (o garantiza que el propietario de dichos derechos ha otorgado expresamente ) a Diazar una licencia, global, libre de regalías, perpetua, irrevocable y no exclusiva con el derecho a sublicenciar, para usar, modificar, reproducir, publicar, adaptar, ejecutar públicamente, mostrar públicamente, mostrar digitalmente y en forma digital, traducir, crear obras derivadas y distribuir dichas publicaciones o incorporar dichas publicaciones en cualquier forma, medio o tecnología conocida ahora o desarrollada más tarde. Usted acepta que no tendrá recurso contra Diazar por cualquier infracción supuesta o real o apropiación indebida de cualquier derecho de propiedad en publicaciones que proporcione a Diazar.</p>
            <p><strong>REGISTRO</strong></p>
            <p>Ciertas secciones de este sitio web pueden requerir que se registre. Si se solicita el registro, usted se compromete a proporcionar información precisa y completa en el registro. Es su responsabilidad informar a Diazar de cualquier cambio en la información. Usted entiende que al registrarse en este Sitio Web, puede recibir actualizaciones periódicas sobre aplicaciones nuevas o actuales de Diazar. Usted puede solicitar darse de baja.</p>
            <p><strong>ANUNCIOS EN ÁREAS INTERACTIVAS</strong></p>
            <p>Si usted participa en áreas interactivas en este sitio web, no debe postear, publicar, cargar o distribuir ningún tipo de publicación que sea ilegal o abusivo. Diazar puede borrar sus mensajes en cualquier momento y por cualquier razón sin notificación o permiso de usted, sin embargo, Diazar no tiene ninguna obligación de supervisar o prevenir publicaciones, no es responsable por el contenido de dichas publicaciones o cualquier contenido vinculado a dichas publicaciones.</p>
            <p><strong>NINGUNA EXPECTATIVA DE PRIVACIDAD</strong></p>
            <p>DIAZAR NO GARANTIZA LA CONFIDENCIALIDAD O PRIVACIDAD DE NINGUNA COMUNICACIÓN O INFORMACIÓN TRANSMITIDA A O DESDE ESTE SITIO WEB. Usted reconoce que cualquier contenido que usted publique en este sitio web es una comunicación pública y no privada. Otros pueden leer y ver sus comunicaciones sin su conocimiento. Diazar no controla ni aprueba el contenido, mensajes, ni la información publicada por otros usuarios de terceros en este sitio web. Diazar no será responsable por la privacidad de cualquiera de sus publicaciones o de cualquier otro de tercero a este sitio web y deniega específicamente cualquier responsabilidad derivada de dichas publicaciones y comunicaciones, incluyendo cualquier contenido objetable.</p>
            <p><strong>PRIVACIDAD</strong></p>
            <p>El uso de este sitio web está sujeto a estas Condiciones de servicio y a la Política de Privacidad de Diazar.&nbsp;</p>	</div>
    )
    return (
        <InfoLayout title="Terminos de Uso">
            { HTML }
        </InfoLayout>
    )
}