import React, {ReactNode, useReducer} from "react";
import {AlertData, AlertDispacher} from "./AlertProviderDomain";
import {alertDataInitialState, AlertReducer} from "./AlertProviderReducer";

interface ViewProps {
    children?: ReactNode | undefined
}

const AlertsStateContext = React.createContext<AlertData[]>( [] );
const AlertDispatcherContext = React.createContext< React.Dispatch<AlertDispacher> >(() => null );

export function useAlertsState(){
    const context = React.useContext(AlertsStateContext);
    if (context === undefined) {
        throw new Error("useAlertsState must be used within a AlertProvider");
    }

    return context;
}

export function useAlertDispatcherState(){
    const context = React.useContext(AlertDispatcherContext);
    if (context === undefined) {
        throw new Error("useAlertDispatcherState must be used within a AlertProvider");
    }

    return context;
}

export const AlertProvider: React.FunctionComponent<ViewProps> = (props) => {
    const [alerts, alertDispatch] = useReducer(AlertReducer, alertDataInitialState)

    return (
        <AlertsStateContext.Provider value={alerts}>
            <AlertDispatcherContext.Provider value={alertDispatch}>
                { props.children }
            </AlertDispatcherContext.Provider>
        </AlertsStateContext.Provider>
    )
}