import React from "react";
import {useAlertDispatcherState, useAlertsState} from "../Context/AlertProvider/AlertProvider";
import {Alert, AlertActionCloseButton, AlertGroup} from "@patternfly/react-core";
import {removeAlert} from "../Context/AlertProvider/AlertProviderActions";
import {duration, now} from "moment";
import Countdown from 'react-countdown';
import {CountdownRendererFn} from "react-countdown/dist/Countdown";

export const DiazarAlerts: React.FunctionComponent = (_props) => {
    const alertsData = useAlertsState()
    const alertDispatch = useAlertDispatcherState()

    const removeAlertById = React.useCallback( (id:string) => {
        removeAlert( id, alertDispatch )
    }, [alertDispatch] )

    const checkFinisheds = React.useCallback( ()=>{
        alertsData.map( data => {
            const diff =  duration( data.finish?.diff( now() ) ).seconds();

            if ( diff <= 0 ){
                removeAlert( data.id, alertDispatch )
            }

            return false
        } );
    }, [alertDispatch, alertsData] )

    const renderer = React.useCallback<CountdownRendererFn>( (cd_props) => {
        if (cd_props.completed) {
            setTimeout( ()=>{
                checkFinisheds()
            }, 300 );

            return <React.Fragment />
        } else {
            return <span>( {cd_props.seconds} )</span>;
        }
    }, [checkFinisheds] )

    const Alerts = React.useMemo( ()=>{
        return (
            alertsData.map( (data, _index) => {
                const title = (
                    <React.Fragment>
                        <Countdown renderer={renderer} date={ data.finish?.toDate() } /> {data.title}
                    </React.Fragment>
                )
                return (
                    <Alert
                        variant={data.variant}
                        title={ title  }
                        actionClose={
                            <AlertActionCloseButton
                                title={data.title}
                                variantLabel={`${data.variant} alert`}
                                onClose={() => removeAlertById(data.id)}
                            />
                        }
                        key={data.id}>
                        {data.body}
                    </Alert>
                )
            } )
        )

    }, [alertsData, removeAlertById, renderer] )

    return (
        <AlertGroup isToast isLiveRegion>
            { Alerts }
        </AlertGroup>
    );
}